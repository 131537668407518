import React from "react"
import { Seo, Layout } from "components"

const Privacy = ({ location }) => {
  return (
    <Layout hasHeaderBackground={true}>
      <Seo title="Privacy" location={location?.pathname} />
      <section className="privacy-section">
        <div class="container mx-auto px-4">
          <h1>Privacy Policy</h1>
          <div>
            <p>
              This Privacy Policy sets out how we, Alpha Modular Limited,
              collect, store and use information about you when you use or
              interact with us and where we otherwise obtain or collect
              information about you. This Privacy Policy is effective from 28
              January 2018.
            </p>
            <p>
              If you have any questions about our Privacy Policy, please contact
              us by sending an email to&nbsp;
              <a href="mailto:alphamodular22@gmail.com">
                alphamodular22@gmail.com
              </a>
            </p>
            <h3>Our Details</h3>
            <h5>Data controller</h5>
            <p>
              The data controller is Alpha Modular, a limited company registered
              in England and Wales (registration number 08756283) the registered
              office of which is at 6 Botha Close, Cambridgeshire, CB23 6HZ
            </p>
            <p>
              You can contact the data controller by writing to Alpha Modular
              Limited, 6 Botha Close, Cambridgeshire, CB23 6HZ or sending an
              email to&nbsp;
              <a href="mailto:alphamodular22@gmail.com">
                alphamodular22@gmail.com
              </a>
            </p>
            <p>
              If you have any questions about this Privacy Policy, please
              contact the data controller.
            </p>
            <h3>Information we collect when you visit our website</h3>
            <p>
              We collect and use information from website visitors in accordance
              with this section and the section entitled Disclosure and
              additional uses of your information.
            </p>
            <h5>Web server log information</h5>
            <p>
              We use a third party server to host our website. Our website
              server automatically logs the IP address you use to access our
              website as well as other information about your visit such as the
              pages accessed, information requested, the date and time of the
              request, the source of your access to our website (e.g. the
              website or URL (link) which referred you to our website), and your
              browser version and operating system.
            </p>
            <p>Our server are cloud based, spread across the globe.</p>
            <p>
              Use of website server log information for IT security purposes
            </p>
            <p>
              Our third party hosting provider stores server logs to ensure
              network and IT security and so that the server and website remain
              uncompromised. This includes analysing log files to help identify
              and prevent unauthorised access to our network, the distribution
              of malicious code, denial of services attacks and other cyber
              attacks, by detecting unusual or suspicious activity.
            </p>
            <p>
              Unless we are investigating suspicious or potential criminal
              activity, we do not make, nor do we allow our website server
              provider to make any attempt to identify you from the information
              collected via server logs.
            </p>
            <p>
              Legal basis for processing: compliance with a legal obligation to
              which we are subject (Article 6(1)(c) of the General Data
              Protection Regulation).
            </p>
            <p>
              Legal obligation: we have a legal obligation to implement
              appropriate technical and organisational measures to ensure a
              level of security appropriate to the risk of our processing of
              information about individuals. Recording access to our website
              using server log files is such a measure.
            </p>
            <p>
              Legal basis for processing: our legitimate interests (Article
              6(1)(f) of the General Data Protection Regulation).
            </p>
            <p>
              Legitimate interests: we have a legitimate interest in using your
              information for the purposes of ensuring network and information
              security.
            </p>
            <h5>Cookies</h5>
            <p>
              Cookies are data files which are sent from a website to a browser
              to record information about users for various purposes.
            </p>
            <p>
              We use cookies, including essential, functional and analytical
              cookies. For further information on how we use cookies, please see
              our cookies policy
            </p>
            <p>
              You can reject some or all of the cookies we use on or via our
              website by changing your browser settings, but doing so can impair
              your ability to use our website or some or all of its features.
              For further information about cookies, including how to change
              your browser settings and other ways in which you can reject
              cookies, please visit&nbsp;
              <a href="whttps://www.allaboutcookies.org/">
                www.allaboutcookies.org
              </a>
            </p>
            <h3>Information we collect when you contact us</h3>
            <p>
              We collect and use information from individuals who contact us in
              accordance with this section and the section entitled Disclosure
              and additional uses of your information.
            </p>
            <h5>Email</h5>
            <p>
              When you send an email to the email address displayed on our
              website, we collect your email address and any other information
              you provide in that email (such as your name, telephone number and
              the information contained in any signature block in your email).
            </p>
            <p>
              Legal basis for processing: our legitimate interests (Article
              6(1)(f) of the General Data Protection Regulation)
            </p>
            <p>
              Legitimate interest(s): responding to enquiries and messages we
              receive and keeping records of correspondence.
            </p>
            <p>Transfer and storage of your information</p>
            <p>
              We use a third party email provider to store emails you send us.
              Our third party email provider is based in the United States of
              America.
            </p>
            <p>
              Emails you send us will be stored outside the European Economic
              Area on our third party email provider’s servers in the United
              States of America. For further information and safeguards used,
              please see the section of this privacy policy entitled Transfers
              of your information outside the European Economic Area.
            </p>
            <h5>Contact form</h5>
            <p>
              When you contact us using our contact form, we collect the
              following information: your name, email address and any
              information you include in the message field. We also collect your
              company name if you provide it.
            </p>
            <p>
              If you do not provide the mandatory information required by our
              contact form, you will not be able to submit the contact form and
              we will not receive your enquiry.
            </p>
            <p>
              Legal basis for processing: our legitimate interests (Article
              6(1)(f) of the General Data Protection Regulation)
            </p>
            <p>
              Legitimate interest(s): responding to enquiries and messages we
              receive and keeping records of correspondence.
            </p>
            <p>Transfer and storage of your information</p>
            <p>
              Messages you send us via our contact form will be stored on our
              web servers in the UK and also transferred to our email provider’s
              servers outside the European Economic Area.
            </p>
            <p>
              For further information about the safeguards used when your
              information is transferred outside the European Economic Area, see
              the section of this privacy policy below entitled Transfers of
              your information outside the European Economic Area.
            </p>
            <h5>Phone</h5>
            <p>
              When you contact us by phone, we collect your phone number and any
              information provide to us during your conversation with us. We do
              not record phone calls.
            </p>
            <p>
              Legal basis for processing: our legitimate interests (Article
              6(1)(f) of the General Data Protection Regulation)
            </p>
            <p>
              Legitimate interest(s): responding to enquiries and messages we
              receive and keeping records of correspondence.
            </p>
            <p>Transfer and storage of your information</p>
            <p>
              Information about your call, such as your phone number and the
              date and time of your call, is processed by our third party
              telephone service provider and stored in the United Kingdom.
            </p>
            <h5>Post</h5>
            <p>
              If you contact us by post, we will collect any information you
              provide to us in any postal communications you send us.
            </p>
            <p>
              Legal basis for processing: our legitimate interests (Article
              6(1)(f) of the General Data Protection Regulation)
            </p>
            <p>
              Legitimate interest(s): responding to enquiries and messages we
              receive and keeping records of correspondence.
            </p>
            <p>Transfer and storage of your information</p>
            <p>
              Information you send us by post is stored in the United Kingdom.
            </p>
            <h3>Information we collect when you interact with our website</h3>
            <p>
              We collect and use information from individuals who interact with
              particular features of our website in accordance with this section
              and the section entitled Disclosure and additional uses of your
              information.
            </p>
            <h5>E-Newsletter</h5>
            <p>
              When you sign up for our e-newsletter to receive information news
              and offers about our products and services on our website we
              collect your email address.
            </p>
            <p>
              Legal basis for processing: your consent (Article 6(1)(a) of the
              General Data Protection Regulation).
            </p>
            <p>
              Consent: you give your consent to us sending you our e-newsletter
              by signing up to receive it using the steps described above.
            </p>
            <p>Transfer and storage of your information</p>
            <p>
              We use a third party service to send out our e-newsletter and
              administer our mailing list, called Zoho. You can access their
              privacy policy here
            </p>
            <h5>Registering on our website</h5>
            <p>
              When you register and create an account on our website (at
              checkout), we collect the following information: your name,
              company name (if applicable), your address (including country,
              street address, town/city and postcode), phone number, email
              address and password (we do not view your password).
            </p>
            <p>
              If you do not provide the mandatory information required by the
              registration form, you will not be able to register or create an
              account on our website.
            </p>
            <p>
              Legal basis for processing: our legitimate interests (Article
              6(1)(f) of the General Data Protection Regulation).
            </p>
            <p>
              Legitimate interest: registering and administering accounts on our
              website to provide you access to content and updates you have
              purchased and facilitate the efficient running and operation of
              our business.
            </p>
            <p>Transfer and storage of your information</p>
            <p>
              Information you submit to us via the registration form on our
              website will be stored within the European Economic Area on our
              third party hosting provider’s servers in the United Kingdom.
            </p>
            <h3>
              Information we collect when you place an order on our website
            </h3>
            <p>
              We collect and use information from individuals who place an order
              on our website in accordance with this section and the section
              entitled Disclosure and additional uses of your information
            </p>
            <h5>Information we collect when you place an order</h5>
            <p>
              When you place an order for goods or services on our website, we
              collect the following mandatory information: name, email address,
              phone number, billing address, company name (if applicable), VAT
              number (if applicable) and account password (if you create an
              account).
            </p>
            <p>
              If you do not provide this information, we will not be able to
              purchase goods or services from us on our website or enter into a
              contract with us.
            </p>
            <p>
              Legal basis for processing: necessary to perform a contract
              (Article 6(1)(b) of the General Data Protection Regulation).
            </p>
            <p>
              Reason why necessary to perform a contract: other than phone
              number and password, we need the mandatory information collected
              by our checkout form to establish who the contract is with and to
              contact you to fulfil our obligations under the contract,
              including sending you receipts and order confirmations.
            </p>
            <p>
              Legal basis for processing: compliance with a legal obligation
              (Article 6(1)(c) of the General Data Protection Regulation).
            </p>
            <p>
              Legal obligation: other than phone number and password, we have a
              legal obligation to issue you with an invoice for the goods and
              services you purchased from us where you are VAT registered and we
              require the mandatory information collected by our checkout form
              for this purpose.
            </p>
            <p>
              Legal basis for processing: we collect your phone number and
              password in our legitimate interests (Article 6(1)(f) of the
              General Data Protection Regulation).
            </p>
            <p>Legitimate interest(s):</p>
            <p>
              we collect your password for our legitimate interests in: (i)
              registering and administering accounts on our website to provide
              you access to content and updates you have purchased; and (ii) to
              facilitate the efficient running and operation of our business.
            </p>
            <p>
              We collect your phone number for our legitimate interest of more
              easily and effectively contacting you in relation to your order
              and your account.
            </p>
            <h5>Marketing communications</h5>
            <p>
              At checkout you will have the option of receiving marketing
              communications from us.
            </p>
            <p>Offers relating to our goods and services</p>
            <p>
              You can opt in to receiving marketing communications from us in
              relation to our goods and services by email by ticking the box ‘I
              would like to receive offers about products and services from GDPR
              Privacy Policy by email’.
            </p>
            <p>
              We will send you marketing communications in relation to our goods
              and services only if you opt-in to receive them.
            </p>
            <p>
              Legal basis for processing: consent (Article 6(1)(a) of the
              General Data Protection Regulation).
            </p>
            <p>
              Consent: you give your consent to us sending you information about
              our goods and services by signing up to receive such information
              in accordance with the steps described above.
            </p>
            <p>Transfer and storage of your information</p>
            <p>
              We use a third party service for marketing communication, called
              Zoho. You can access their privacy policy&nbsp;
              <a href="https://www.google.com/url?q=https://www.zoho.eu/privacy.html&amp;sa=D&amp;ust=1584615881678000">
                here
              </a>
            </p>
            <h5>Processing your payment</h5>
            <p>
              After you place an order on our website you will need to make
              payment for the goods or services you have ordered. In order to
              process your payment we use a number of third party payment
              processors, including PayPal and Stripe. Your payment will be
              processed by the payment provider you choose to process your
              payment via a payment gateway.
            </p>
            <p>
              Legal basis for processing: necessary to perform a contract
              (Article 6(1)(b) of the General Data Protection Regulation).
            </p>
            <p>
              Reason why necessary to perform a contract: to fulfil your
              contractual obligation to pay for the goods or services you have
              ordered from us.
            </p>
            <p>Third party payment processors</p>
            <p>
              The third party payment processors we use all collect, use and
              process your information, including payment information, in
              accordance with their privacy policies. You can access their
              privacy policies via the following links:
            </p>
            <ul>
              <li>
                <p>
                  <a href="https://www.google.com/url?q=https://www.paypal.com/uk/webapps/mpp/ua/privacy-full&amp;sa=D&amp;ust=1584615881679000">
                    PayPal
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <a href="https://www.google.com/url?q=https://stripe.com/gb/privacy&amp;sa=D&amp;ust=1584615881680000">
                    Stripe
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <a href="https://www.google.com/url?q=https://www.zoho.eu/privacy.html&amp;sa=D&amp;ust=1584615881680000">
                    Zoho
                  </a>
                </p>
              </li>
            </ul>
            <p>
              We use WooCommerce as the ecommerce platform for processing orders
              on our website. When you place orders on our website, your
              information will be processed by WooCommerce. WooCommerce does not
              collect any information about you when it processes your order on
              our site. WooCommerce is owned by Automattic and you access their
              privacy policy&nbsp;
              <a href="https://www.google.com/url?q=https://automattic.com/privacy/&amp;sa=D&amp;ust=1584615881680000">
                here
              </a>
            </p>
            <h5>Transfer and storage of your information</h5>
            <p>
              Stripe is based in the United States of America. Information
              relating to Stripe’s processing of your payment may be transferred
              outside the European Economic Area to the United States of
              America. Stripe has self-certified itself with the EU-U.S. Privacy
              Shield for the purpose of such transfers.
            </p>
            <p>
              PayPal may transfer information relating to your transaction and
              the processing of your transaction outside the European Economic
              Area. Where they do so, they will put appropriate safeguards in
              place.
            </p>
            <p>
              Zoho share your personal information and service data within the
              Zoho Group. By accessing or using their products and services or
              otherwise providing personal information or service data to them,
              you consent to the processing, transfer, and storage of your
              personal information or Service Data within the United States of
              America, the European Economic Area (EEA) and other countries
              where Zoho operates.
            </p>
            <p>
              For further information about the safeguards used when your
              information is transferred outside the European Economic Area, see
              the section of this privacy policy below entitled Transfers of
              your information outside the European Economic Area.
            </p>
            <h3>
              How we collect or obtain information about you from third parties
            </h3>
            <p>
              This section sets out how we obtain or collect information about
              you from third parties.
            </p>
            <h5>Information received from third parties</h5>
            <p>
              Generally, we do not receive information about you from third
              parties. The third parties from which we receive information about
              you will generally include other businesses and clients we work
              with from time to time who may recommend our services to you.
              These could be business in any industry, sector, sub-sector or
              location.
            </p>
            <p>
              It is also possible that third parties with whom we have had no
              prior contact may provide us with information about you.
            </p>
            <p>
              Information we obtain from third parties will generally be your
              name and contact details, but will include any additional
              information about you which they provide to us.
            </p>
            <p>
              Legal basis for processing: necessary to perform a contract or to
              take steps at your request to enter into a contract (Article
              6(1)(b) of the General Data Protection Regulation).
            </p>
            <p>
              Reason why necessary to perform a contract: where a third party
              has passed on information about you to us (such as your name and
              email address) in order for us to provide services to you, we will
              process your information in order to take steps at your request to
              enter into a contract with you and perform a contract with you (as
              the case may be).
            </p>
            <p>
              Legal basis for processing: consent (Article 6(1)(a) of the
              General Data Protection Regulation).
            </p>
            <p>
              Consent: where you have asked that a third party to share
              information about you with us and the purpose of sharing that
              information is not related to the performance of a contract or
              services by us to you, we will process your information on the
              basis of your consent, which you give by asking the third party in
              question to pass your information on to us.
            </p>
            <p>
              Legal basis for processing: our legitimate interests (Article
              6(1)(f) of the General Data Protection Regulation).
            </p>
            <p>
              Legitimate interests: where a third party has shared information
              about you with us and you have not consented to the sharing of
              that information, we will have a legitimate interest in processing
              that information in certain circumstances.
            </p>
            <p>
              For example, we would have a legitimate interest in processing
              your information to perform our obligations under a sub-contract
              with the third party, where the third party has the main contract
              with you. Our legitimate interest is the performance of our
              obligations under our sub-contract.
            </p>
            <p>
              Similarly, third parties may pass on information about you to us
              if you have infringed or potentially infringed any of our legal
              rights. In this case, we will have a legitimate interest in
              processing that information to investigate and pursue any such
              potential infringement.
            </p>
            <h5>Information obtained by us from third parties</h5>
            <p>
              In certain circumstances (for example, to verify the information
              we hold about you or obtain missing information we require to
              provide you with a service) we will obtain information about you
              from certain publicly accessible sources, both EU and non-EU, such
              as Companies House, online customer databases, business
              directories, media publications, social media, and websites
              (including your own website if you have one). We may do this, for
              example, if we have insufficient information to be able to contact
              you or to better understand your business.
            </p>
            <p>
              Legal basis for processing: necessary to perform a contract or to
              take steps at your request to enter into a contract (Article
              6(1)(b) of the General Data Protection Regulation).
            </p>
            <p>
              Reason why necessary to perform a contract: where you have entered
              into a contract or requested that we enter into a contract with
              you, in certain circumstances, we will obtain information about
              you from public sources in order to enable us to understand your
              business and provide services to you or services to a sufficient
              standard.
            </p>
            <p>
              For example, we would obtain and/or verify your email address from
              your website or from a directory where you ask us to send you
              information by email but we do not possess the information or we
              need to confirm that we have recorded your email address
              correctly.
            </p>
            <p>
              Legal basis for processing: our legitimate interests (Article
              6(1)(f) of the General Data Protection Regulation).
            </p>
            <p>
              Legitimate interests: in certain circumstances, we will have a
              legitimate interest in obtaining information about you from public
              and private sources. For example, if you have infringed or we
              suspect that you have infringed any of our legal rights, we will
              have a legitimate interest in obtaining and processing information
              about you from such sources in order to investigate and pursue any
              suspected or potential infringement.
            </p>
            <h3>Disclosure and additional uses of your information</h3>
            <p>
              This section sets out the circumstances in which will disclose
              information about you to third parties and any additional purposes
              for which we use your information.
            </p>
            <h5>Disclosure of your information to service providers</h5>
            <p>
              We use a number of third parties to provide us with services which
              are necessary to run our business or to assist us with running our
              business and who process your information for us on our behalf.
              These include the following:
            </p>
            <ul>
              <li>
                <p>Telephone providers (UK),</p>
              </li>
              <li>
                <p>Email provider (USA),</p>
              </li>
              <li>
                <p>Mailing list provider (USA),</p>
              </li>
              <li>
                <p>IT service providers (UK),</p>
              </li>
              <li>
                <p>Web developers (UK &amp; USA), and</p>
              </li>
              <li>
                <p>Hosting provider (UK &amp; USA).</p>
              </li>
            </ul>
            <p>
              Apart from our email provider and our mailing list provider, which
              are located in the United States of America, our service providers
              are located in the United Kingdom.
            </p>
            <p>
              Your information will be shared with these service providers only
              where necessary to enable us to run our business.
            </p>
            <h5>Disclosure of your information for legal reasons</h5>
            <p>
              Indicating possible criminal acts or threats to public security to
              a competent authority
            </p>
            <p>
              If we suspect that criminal or potential criminal conduct has been
              occurred, we will in certain circumstances need to contact an
              appropriate authority, such as the police. This could be the case,
              for instance, if we suspect that a fraud or a cyber crime has been
              committed or if we receive threats or malicious communications
              towards us or third parties.
            </p>
            <p>
              We will generally only need to process your information for this
              purpose if you were involved or affected by such an incident in
              some way.
            </p>
            <p>
              Legal basis for processing: our legitimate interests (Article
              6(1)(f) of the General Data Protection Regulation).
            </p>
            <p>
              Legitimate interests: preventing crime or suspected criminal
              activity (such as fraud).
            </p>
            <p>
              <b>
                In connection with the enforcement or potential enforcement our
                legal rights
              </b>
            </p>
            <p>
              We will use your information in connection with the enforcement or
              potential enforcement of our legal rights, including sharing
              information with debt collection agencies if you do not pay
              amounts owed to us when you are contractually obliged to do so.
              Our legal rights may be contractual (where we have entered into a
              contract with you) or non-contractual (such as legal rights that
              we have under copyright law or tort law).
            </p>
            <p>
              Legal basis for processing: our legitimate interests (Article
              6(1)(f) of the General Data Protection Regulation).
            </p>
            <p>
              Legitimate interest: enforcing our legal rights and taking steps
              to enforce our legal rights.
            </p>
            <p>
              <b>
                In connection with a legal or potential legal dispute or
                proceedings
              </b>
            </p>
            <p>
              We may need to use your information if we are involved in a
              dispute with you or a third party for example, either to resolve
              the dispute or as part of any mediation, arbitration or court
              resolution or similar process.
            </p>
            <p>
              Legal basis for processing: our legitimate interests (Article
              6(1)(f) of the General Data Protection Regulation).
            </p>
            <p>
              Legitimate interest(s): resolving disputes and potential disputes.
            </p>
            <p>
              <b>
                For ongoing compliance with laws, regulations and other legal
                requirements
              </b>
            </p>
            <p>
              We will use and process your information in order to comply with
              legal obligations to which we are subject. For example, we may
              need to disclose your information pursuant to a court order or
              subpoena if we receive one or to the National Crime Agency in
              connection with suspected or potential money laundering matters.
            </p>
            <p>
              Legal basis for processing: compliance with a legal obligation
              (Article 6(1)(c) of the General Data Protection Regulation).
            </p>
            <p>
              Legal obligation(s): legal obligations to disclose information
              which are part of the laws of England and Wales or if they have
              been integrated into the United Kingdom’s legal framework (for
              example in the form of an international agreement which the United
              Kingdom has signed).
            </p>
            <p>
              Legal basis for processing: our legitimate interests (Article
              6(1)(f) of the General Data Protection Regulation).
            </p>
            <p>
              Legitimate interest: where the legal obligations are part of the
              laws of another country and have not been integrated into the
              United Kingdom’s legal framework, we have a legitimate interest in
              complying with these obligations.
            </p>
            <h5>Disclosure of your information to other third parties</h5>
            <p>
              We disclose your information to other third parties in specific
              circumstances, as set out below.
            </p>
            <p>
              <b>Providing information to Google Inc.</b>
            </p>
            <p>
              Google collects information through our use of Google Analytics on
              our website. Google uses this information, including IP addresses
              and information from cookies, for a number of purposes, such as
              improving its Google Analytics service. Information is shared with
              Google on an aggregated and anonymised basis. To find out more
              about what information Google collects, how it uses this
              information and how to control the information sent to Google,
              please see the partners page of Google’s privacy policy
            </p>
            <p>
              Legal basis for processing: our legitimate interests (Article
              6(1)(f) of the General Data Protection Regulation).
            </p>
            <p>
              Legitimate interest(s): meeting our contractual obligations to
              Google under our Google Analytics Terms of Service and allowing
              the Google Analytics to work on our website
            </p>
            <p>
              You can opt out of Google Analytics by installing the browser
              plugin&nbsp;
              <a href="https://www.google.com/url?q=https://tools.google.com/dlpage/gaoptout&amp;sa=D&amp;ust=1584615881690000">
                here
              </a>
            </p>
            <p>
              <b>
                Sharing your information with a prospective or actual purchaser
                or seller in the context of a business or asset sale or
                acquisition by us, a merger or similar business combination
                event, whether actual or potential
              </b>
            </p>
            <p>
              Legal basis for processing: legitimate interests (Article 6(1)(f)
              of the General Data Protection Regulation).
            </p>
            <p>
              Legitimate interest(s): sharing your information with a
              prospective purchaser, seller or similar person in order to allow
              such a transaction to take place.
            </p>
            <p>
              <b>
                Sharing your information with third parties, which are either
                related to or associated with the running of our business, where
                it is necessary for us to do so. These third parties include our
                accountants, advisors, affiliates, business partners,
                independent contractors, and insurers
              </b>
              &nbsp;Further information on each of these third parties is set
              out below.
            </p>
            <p>
              Legal basis for processing: our legitimate interests (Article
              6(1)(f) of the General Data Protection Regulation).
            </p>
            <p>
              Legitimate interest: running and managing our business
              efficiently.
            </p>
            <p>
              <b>Accountants</b>
            </p>
            <p>
              We share information with our accountants for tax purposes. For
              example, we share invoices we issue and receive with our
              accountants for the purpose of completing tax returns and our end
              of year accounts.
            </p>
            <p>Our accountants are located in the United Kingdom.</p>
            <p>
              <b>Advisors</b>
            </p>
            <p>
              Occasionally, we obtain advice from advisors, such as accountants,
              financial advisors, lawyers and other specialists. We will share
              your information with these third parties only where it is
              necessary to enable these third parties to be able to provide us
              with the relevant advice.
            </p>
            <p>Our advisors are located in the United Kingdom.</p>
            <p>
              <b>Affiliates</b>
            </p>
            <p>
              Affiliates are individuals or entities we work with to promote our
              business by various means, including by advertising our services
              on their websites, for example. Our affiliates can be in in any
              industry, sector or sub-sector. Affiliates will share information
              with us and we will share information with them where you have
              expressed an interest in our products or services.
            </p>
            <p>Our affiliates are located in the United Kingdom.</p>
            <p>
              <b>Business partners</b>
            </p>
            <p>
              Business partners are businesses we work with which provide goods
              and services which are complementary to our own or which allow us
              to provide goods or services which we could not provide on our
              own. We share information with our business partners where you
              have requested services which they provide whether independently
              from, or in connection with or own services.
            </p>
            <p>Our business partners are located in the United Kingdom.</p>
            <p>
              <b>Independent contractors</b>
            </p>
            <p>
              Occasionally, we use independent contractors in our business. Your
              information will be shared with independent contractors only where
              it is necessary for them to perform the function we have hired
              them perform in relation to our business.
            </p>
            <p>Our independent contractors are located all over the world.</p>
            <p>
              <b>Insurers</b>&nbsp;We will share your information with our
              insurers where it is necessary to do so, for example in relation
              to a claim or potential claim we receive or make or under our
              general disclosure obligations under our insurance contract with
              them.
            </p>
            <p>Our insurers are located in the United Kingdom.</p>
            <h3>How long we retain your information</h3>
            <p>
              This section sets out how long we retain your information. We have
              set out specific retention periods where possible. Where that has
              not been possible, we have set out the criteria we use to
              determine the retention period.
            </p>
            <h5>Retention and Disposal of Data</h5>
            <p>
              The business will not retain Personal Data for longer than
              necessary.
            </p>
            <ul>
              <li>
                <p>
                  <b>Clients:</b>&nbsp;Data for clients and their customers is
                  generally retained for six years in order to comply with legal
                  and regulatory requirements.
                </p>
              </li>
              <li>
                <p>
                  <b>Staff:</b>&nbsp;Digital Velocity will create a personnel
                  file for each member of staff and will keep this for the
                  duration of employment and for a minimum of six months after a
                  staff member leaves employment. After six months, we will
                  review the personnel file and delete any personal data that we
                  do not need. We will retain the following personal data for
                  the following periods of time:
                </p>
              </li>
            </ul>
            <table>
              <tbody>
                <tr>
                  <th>
                    <p>Data</p>
                  </th>
                  <th>
                    <p>Period of Retention</p>
                  </th>
                </tr>
                <tr>
                  <td>
                    <p>
                      Data confirming payments due to you. For example, your
                      contract of employment and any information about salary or
                      benefits.
                    </p>
                  </td>
                  <td>
                    <p>6 years after you leave your employment</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Data relating to taxes, National Insurance contributions
                      and other charges paid in relation to you.
                    </p>
                  </td>
                  <td>
                    <p>7 years after you leave your employment</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Data relating to any accidents or injuries at work.</p>
                  </td>
                  <td>
                    <p>3 years after you leave your employment</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Data relating to any references given in relation to you.
                    </p>
                  </td>
                  <td>
                    <p>1 year after the date of the reference</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <ul>
              <li>
                <p>
                  <b>Recruitment Records:</b>&nbsp;Information relating to
                  unsuccessful applicants will be kept for 36 months from
                  receipt of their application.
                </p>
              </li>
              <li>
                <p>
                  <b>Disposal of Records:</b>&nbsp;all Personal Data must be
                  disposed of in a way that protects the rights and privacy of
                  Data Subjects (e.g. shredding).
                </p>
              </li>
            </ul>
            <h5>Specific retention periods</h5>
            <p>
              <b>Order information:</b>&nbsp;when you place an order for goods
              and services, we retain that information for a minimum period of
              six years following the end of the financial year in which you
              placed your order, in accordance with our legal obligation to keep
              records for tax purposes under paragraph 6, Schedule 11 of the
              Value Added Tax Act 1994.
            </p>
            <p>
              <b>Correspondence and enquiries:</b>&nbsp;when you make an enquiry
              or contact us by email or via our contact form, we will retain
              your information for as long as it takes to respond to and resolve
              your enquiry, and for 6 further month(s), after which point we
              will delete your information.
            </p>
            <p>
              <b>Mailing list:</b>&nbsp;we retain the information you used to
              sign up for our newsletter for as long as you remain subscribed
              (i.e. you do not unsubscribe) or if we decide to cancel our
              newsletter service, whichever occurs first.
            </p>
            <h5>Criteria for determining retention periods</h5>
            <p>
              In any other circumstances, we will retain your information for no
              longer than necessary, taking into account the following:
            </p>
            <ul>
              <li>
                <p>
                  the purpose(s) and use of your information both now and in the
                  future (such as whether it is necessary to continue to store
                  that information in order to continue to perform our
                  obligations under a contract with you or to contact you in the
                  future);
                </p>
              </li>
              <li>
                <p>
                  whether we have any legal obligation to continue to process
                  your information (such as any record-keeping obligations
                  imposed by relevant law or regulation);
                </p>
              </li>
              <li>
                <p>
                  whether we have any legal basis to continue to process your
                  information (such as your consent);
                </p>
              </li>
              <li>
                <p>
                  how valuable your information is (both now and in the future);
                </p>
              </li>
              <li>
                <p>
                  any relevant agreed industry practices on how long information
                  should be retained; the levels of risk, cost and liability
                  involved with us continuing to hold the information;
                </p>
              </li>
              <li>
                <p>
                  how hard it is to ensure that the information can be kept up
                  to date and accurate; and
                </p>
              </li>
              <li>
                <p>
                  any relevant surrounding circumstances (such as the nature and
                  status of our relationship with you).
                </p>
              </li>
            </ul>
            <h3>How we secure your information</h3>
            <h5>Measures taken to secure your information</h5>
            <p>
              We take appropriate technical and organisational measures to
              secure your information and to protect it against unauthorised or
              unlawful use and accidental loss or destruction, including:
            </p>
            <ul>
              <li>
                <p>
                  only sharing and providing access to your information to the
                  minimum extent necessary, subject to confidentiality
                  restrictions where appropriate, and on an anonymised basis
                  wherever possible;
                </p>
              </li>
              <li>
                <p>using secure servers to store your information;</p>
              </li>
              <li>
                <p>
                  verifying the identity of any individual who requests access
                  to information prior to granting them access to information;
                  and
                </p>
              </li>
              <li>
                <p>
                  using Secure Sockets Layer (SSL) software or other similar
                  encryption technologies to encrypt any payment transactions
                  you make on or via our website.
                </p>
              </li>
            </ul>
            <h5>Transmission of information to use by email</h5>
            <p>
              Transmission of information over the internet is not entirely
              secure, and if you submit any information to us over the internet
              (whether by email, via our website or any other means), you do so
              entirely at your own risk.
            </p>
            <p>
              We cannot be responsible for any costs, expenses, loss of profits,
              harm to reputation, damages, liabilities or any other form of loss
              or damage suffered by you as a result of your decision to transmit
              information to us by such means.
            </p>
            <h5>Publication of Information</h5>
            <p>
              The business publishes a number of items that includes Personal
              Data and will continue to do so. These include:
            </p>
            <ul>
              <li>
                <p>Staff information/photographs on the firm’s website</p>
              </li>
              <li>
                <p>
                  Information including photographs in newsletters, press
                  articles and, as and when appropriate, tender applications
                </p>
              </li>
            </ul>
            <h5>Breaches</h5>
            <p>
              A data protection breach means a breach of security leading to the
              accidental or unlawful destruction, loss, alteration, unauthorised
              disclosure of, or access to, Personal Data transmitted, stored or
              otherwise processed.
            </p>
            <p>
              Everybody working for Alpha Modular has a duty to report any
              actual or suspected data protection breach without delay to the
              Privacy Manager or, in their absence, their line manager.
            </p>
            <p>
              Breaches will be reported to the ICO by the Privacy Manager
              without undue delay and, where feasible, not later than 72 hours
              after having become aware of the breach, unless, we are able to
              demonstrate that the Personal Data breach is unlikely to result in
              a risk to the rights and freedom of Data Subjects. Where there is
              a high risk to the rights and freedoms of individuals, we must
              also notify the affected individuals.
            </p>
            <p>
              The Privacy Manager will maintain a central register of the
              details of any data protection breaches.
            </p>
            <h3>Risk Assessment</h3>
            <p>
              This policy applies to all projects, regardless of types, and is
              applicable to all staff, students,
            </p>
            <p>
              visitors, contractors and data processors working for, or on
              behalf of Alpha Modular.
            </p>
            <p>
              All project owners or managers are responsible for registering and
              assessing potential risks
            </p>
            <p>
              regarding the project they are involved in, particularly if it
              involves processing personal or
            </p>
            <p>sensitive data.</p>
            <p>
              <b>Screening questions</b>
            </p>
            <p>Yes/ No (If yes, please provide details)</p>
            <ul>
              <li>
                <p>
                  Will the project involve the collection of new information
                  about individuals?
                </p>
              </li>
              <li>
                <p>
                  Will the project compel individuals to provide information
                  about
                </p>
              </li>
              <li>
                <p>themselves?</p>
              </li>
              <li>
                <p>
                  Will information about individuals be disclosed to
                  organisations or people
                </p>
              </li>
              <li>
                <p>
                  who have not previously had routine access to the information?
                </p>
              </li>
              <li>
                <p>
                  Are you using information about individuals for a purpose it
                  is not currently
                </p>
              </li>
              <li>
                <p>used for, or in a way it is not currently used?</p>
              </li>
              <li>
                <p>
                  Does the project involve you using new technology which might
                  be
                </p>
              </li>
              <li>
                <p>
                  perceived as being privacy intrusive? For example, the use of
                  biometrics
                </p>
              </li>
              <li>
                <p>or facial recognition</p>
              </li>
              <li>
                <p>
                  Will the project result in you making decisions or taking
                  action against
                </p>
              </li>
              <li>
                <p>
                  individuals in ways which can have a significant impact on
                  them?
                </p>
              </li>
              <li>
                <p>
                  Is the information about individuals of a kind particularly
                  likely to raise
                </p>
              </li>
              <li>
                <p>
                  privacy concerns or expectations? For example, health records,
                  criminal
                </p>
              </li>
              <li>
                <p>
                  records or other information that people would consider to be
                  particularly
                </p>
              </li>
              <li>
                <p>private.</p>
              </li>
              <li>
                <p>
                  Will the project require you to contact individuals in ways
                  which they may
                </p>
              </li>
              <li>
                <p>find intrusive?</p>
              </li>
            </ul>
            <h3>
              Transfers of your information outside the European Economic Area
            </h3>
            <p>
              Your information will be transferred and stored outside the
              European Economic Area (EEA) in the circumstances set out below.
            </p>
            <p>
              We will also transfer your information outside the EEA or to an
              international organisation in the unlikely event that we are
              required to comply with legal obligations to which we are subject
              (compliance with a court order, for example). Where we are
              required to do so, we will ensure appropriate safeguards and
              protections are in place.
            </p>
            <h5>Email</h5>
            <p>
              Information you submit to us by email is transferred outside the
              EEA and stored on our third party email provider’s servers.
            </p>
            <p>
              Country of storage: United States of America. This country is not
              subject to an adequacy decision by the European Commission.
            </p>
            <h5>Contact form</h5>
            <p>
              Information you submit via our contact form is stored on our
              server in the United Kingdom but is also forwarded to our email
              address and transferred outside the European Economic Area. For
              more information, please see ‘Email’ immediately above.
            </p>
            <h5>E-Newsletter</h5>
            <p>
              Information you submit to us when you sign up for our newsletter
              (your email address) is transferred outside the EEA and stored on
              our third party mailing list provider’s servers. Our third party
              mailing list provider is Zoho. You can access their privacy policy
              here
            </p>
            <h5>Payment processing</h5>
            <p>
              When you pay for goods and services on your site, if you select
              Stripe or PayPal, information about your order and the processing
              of your order may be transferred outside the European Economic
              Area.
            </p>
            <p>
              <b>Stripe</b>
            </p>
            <p>
              Information processed by Stripe is transferred outside the EEA and
              stored on Stripe’s servers. You can access Stripe’s privacy
              policy&nbsp;
              <a href="https://www.google.com/url?q=https://stripe.com/gb/privacy&amp;sa=D&amp;ust=1584619702729000">
                here
              </a>
            </p>
            <p>
              Country of storage: United States of America. This country is not
              subject to an adequacy decision by the European Commission.
            </p>
            <p>
              Safeguard(s) used: Stripe has self-certified its compliance with
              the EU-U.S. Privacy Shield which is available&nbsp;
              <a href="https://www.google.com/url?q=http://ec.europa.eu/justice/data-protection/international-transfers/adequacy/index_en.htm&amp;sa=D&amp;ust=1584619702729000">
                here
              </a>
              . The EU-U.S. Privacy Shield is an approved certification
              mechanism under Article 42 of the General Data Protection
              Regulation, which is permitted under Article 46(2)(f) of the
              General Data Protection Regulation. You can access the European
              Commission decision on the adequacy of the EU-U.S. Privacy
              Shield&nbsp;
              <a href="https://www.google.com/url?q=http://ec.europa.eu/justice/data-protection/international-transfers/adequacy/index_en.htm&amp;sa=D&amp;ust=1584619702730000">
                here
              </a>
            </p>
            <p>
              <b>PayPal</b>
            </p>
            <p>
              PayPal may transfer information they process about your order
              outside the EEA. Where they do so, they will ensure appropriate
              safeguards are in place.
            </p>
            <p>
              You can access PayPal’s privacy policy&nbsp;
              <a href="https://www.google.com/url?q=https://www.zoho.eu/privacy.html&amp;sa=D&amp;ust=1584619702731000">
                here
              </a>
            </p>
            <h3>Your rights in relation to your information</h3>
            <h5>Your rights</h5>
            <p>
              Subject to certain limitations on certain rights, you have the
              following rights in relation to your information, which you can
              exercise by writing to Alpha Modular Limited, 6 Botha close,
              Cambridgeshire, CB23 6HZ or sending an email to&nbsp;
              <a href="mailto:alphamodular22@gmail.com">
                alphamodular22@gmail.com
              </a>
              :
            </p>
            <ul>
              <li>
                <p>
                  to request access to your information and information related
                  to our use and processing of your information;
                </p>
              </li>
              <li>
                <p>
                  to request the correction or deletion of your information;
                </p>
              </li>
              <li>
                <p>to request that we restrict our use of your information;</p>
              </li>
              <li>
                <p>
                  to receive information which you have provided to us in a
                  structured, commonly used and machine-readable format (e.g. a
                  CSV file) and the right to have that information transferred
                  to another data controller (including a third party data
                  controller);
                </p>
              </li>
              <li>
                <p>
                  to object to the processing of your information for certain
                  purposes (for further information, see the section below
                  entitled Your right to object to the processing of your
                  information); and
                </p>
              </li>
              <li>
                <p>
                  to withdraw your consent to our use of your information at any
                  time where we rely on your consent to use or process that
                  information. Please note that if you withdraw your consent,
                  this will not affect the lawfulness of our use and processing
                  of your information on the basis of your consent before the
                  point in time when you withdraw your consent.
                </p>
              </li>
            </ul>
            <p>
              In accordance with Article 77 of the General Data Protection
              Regulation, you also have the right to lodge a complaint with a
              supervisory authority, in particular in the Member State of your
              habitual residence, place of work or of an alleged infringement of
              the General Data Protection Regulation.
            </p>
            <p>
              For the purposes of the UK, the supervisory authority is the
              Information Commissioner’s Office (ICO), the contact details of
              which are available&nbsp;
              <a href="https://www.google.com/url?q=https://ico.org.uk/global/contact-us/&amp;sa=D&amp;ust=1584619702733000">
                here
              </a>
            </p>
            <h5>Further information about your rights</h5>
            <p>
              The above rights are provided in summary form only and certain
              limitations apply to many of these rights. For further information
              about your rights in relation to your information, including any
              limitations which apply, please visit the following pages on the
              ICO’s website:
            </p>
            <ul>
              <li>
                <p>Guide To The General Data Protection Regulation</p>
              </li>
              <li>
                <p>Is My Information Being Handled Correctly</p>
              </li>
            </ul>
            <p>
              You can also find out further information about your rights, as
              well as information on any limitations which apply to those
              rights, by reading the underlying legislation contained in
              Articles 12 to 22 and 34 of the General Data Protection
              Regulation, which is available&nbsp;
              <a href="https://www.google.com/url?q=http://ec.europa.eu/justice/data-protection/reform/files/regulation_oj_en.pdf&amp;sa=D&amp;ust=1584619702734000">
                here
              </a>
            </p>
            <h5>
              Verifying your identity where you request access to your
              information
            </h5>
            <p>
              Where you request access to your information, we are required by
              law to use all reasonable measures to verify your identity before
              doing so.
            </p>
            <p>
              These measures are designed to protect your information and to
              reduce the risk of identity fraud, identity theft or general
              unauthorised access to your information.
            </p>
            <p>
              <b>How we verify your identity</b>
            </p>
            <p>
              Where we possess appropriate information about you on file, we
              will attempt to verify your identity using that information.
            </p>
            <p>
              If it is not possible to identity you from such information, or if
              we have insufficient information about you, we may require
              original or certified copies of certain documentation in order to
              be able to verify your identity before we are able to provide you
              with access to your information.
            </p>
            <p>
              We will be able to confirm the precise information we require to
              verify your identity in your specific circumstances if and when
              you make such a request.
            </p>
            <h3>Your right to object to the processing of your information</h3>
            <p>Your right to object</p>
            <p>
              You have the following rights in relation to your information,
              which you may exercise by writing to Alpha Modular Limited, 6
              Botha Close, Cambridgeshire, CB23 6HZ or sending an email to&nbsp;
              <a href="mailto:alphamodular22@gmail.com">
                alphamodular22@gmail.com
              </a>
              :
            </p>
            <ul>
              <li>
                <p>
                  to object to us using or processing your information where we
                  use or process it in order to carry out a task in the public
                  interest or for our legitimate interests, including
                  ‘profiling’ (i.e. analysing or predicting your behaviour based
                  on your information) based on any of these purposes; and
                </p>
              </li>
              <li>
                <p>
                  to object to us using or processing your information for
                  direct marketing purposes (including any profiling we engage
                  in that is related to such direct marketing). You may also
                  exercise your right to object to us using or processing your
                  information for direct marketing purposes by:
                </p>
              </li>
              <li>
                <p>
                  clicking the unsubscribe link contained at the bottom of any
                  marketing email we send to you and following the instructions
                  which appear in your browser following your clicking on that
                  link; sending an email to
                </p>
                <p>&nbsp;</p>
                <p>
                  <a href="mailto:alphamodular22@gmail.com">
                    alphamodular22@gmail.com
                  </a>
                </p>
                <p>
                  , asking that we stop sending you marketing communications or
                  by including the words “OPT OUT”. For more information on how
                  to object to our use of information collected from cookies and
                  similar technologies, please see the section entitled How to
                  accept or reject cookies in our cookies policy
                </p>
              </li>
            </ul>
            <h3>Sensitive Personal Information</h3>
            <h5>What is ‘Sensitive Personal Information’</h5>
            <p>
              ‘Sensitive personal information’ is information about an
              individual that reveals their racial or ethnic origin, political
              opinions, religious or philosophical beliefs, or trade union
              membership, genetic information, biometric information for the
              purpose of uniquely identifying an individual, information
              concerning health or information concerning a natural person’s sex
              life or sexual orientation.
            </p>
            <h5>Our policy on Sensitive Personal Information</h5>
            <p>
              We do not knowingly or intentionally collect sensitive personal
              information from individuals, and you must not submit sensitive
              personal information to us.
            </p>
            <p>
              If, however, you inadvertently or intentionally transmit sensitive
              personal information to us, you will be considered to have
              explicitly consented to us processing that sensitive personal
              information under Article 9(2)(a) of the General Data Protection
              Regulation. We will use and process your sensitive personal
              information for the purposes of deleting it.
            </p>
            <h3>Changes to our Privacy Policy</h3>
            <h5>Minor changes to our Privacy Policy</h5>
            <p>
              Where we make minor changes to our Privacy Policy, we will update
              our Privacy Policy with a new effective date stated at the
              beginning of it. Our processing of your information will be
              governed by the practices set out in that new version of the
              Privacy Policy from its effective date onwards.
            </p>
            <h5>Major changes to our Privacy Policy</h5>
            <p>
              Where we make major changes to our Privacy Policy or intend to use
              your information for a new purpose or a different purpose than the
              purposes for which we originally collected it, we will notify you
              by email (where possible) or by posting a notice on our website.
            </p>
            <p>
              We will provide you with the information about the change in
              question and the purpose and any other relevant information before
              we use your information for that new purpose.
            </p>
            <p>
              Wherever required, we will obtain your prior consent before using
              your information for a purpose that is different from the purposes
              for which we originally collected it.
            </p>
            <h3>Additional Information</h3>
            <h5>Copyright</h5>
            <p>
              The copyright in this Privacy Policy is either owned by, or
              licensed to, us and is protected by copyright laws around the
              world and copyright protection software. All intellectual property
              rights in this document are reserved.
            </p>
            <p></p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Privacy
